<template>
  <div
    class="collapse menu-collapse"
    id="collapseSports"
    data-parent="#accordion"
  >
    <div class="menu-accordion p-2">
      <div class="row">
        <div class="col-6">
          <div class="item-wrapper">
            <a @click="setSportMenu(1, 'Soccer')">
              <img src="/menu/ball.png" class="menu-icon-sports-svg" />
              <span style="color: var(--white)"> Football </span>
            </a>
          </div>
          <div class="item-wrapper">
            <a @click="setSportMenu(2, 'Basketball')">
              <img src="/menu/basket-ball.png" class="menu-icon-sports-svg" />
              <span style="color: var(--white)"> Basketball </span>
            </a>
          </div>
          <div class="item-wrapper">
            <a @click="setSportMenu(21, 'Cricket')">
              <img
                src="../../../public/menu/cricket-new.png"
                class="menu-icon-sports-svg"
                style="color: white !important"
              />
              <span style="color: var(--white)"> Cricket </span>
            </a>
          </div>
          <div class="item-wrapper">
            <a @click="setSportMenu(5, 'Tennis')">
              <img
                src="../../../public/menu/tennis-new.png"
                class="menu-icon-sports-svg"
              />
              <span style="color: var(--white)"> Tennis </span>
            </a>
          </div>
          <div class="item-wrapper">
            <a @click="setSportMenu(4, 'IceHockey')">
              <img src="/menu/IceHockey.png" class="menu-icon-sports-svg" />
              <span style="color: var(--white)"> Ice Hockey </span>
            </a>
          </div>
          <div class="item-wrapper">
            <a @click="setSportMenu(16, 'AmericanFootball')">
              <img
                src="/menu/AmericanFootball.png"
                class="menu-icon-sports-svg"
              />
              <span style="color: var(--white)"> American Football </span>
            </a>
          </div>
          <div class="item-wrapper">
            <a @click="setSportMenu(10, 'Boxing')">
              <img src="/menu/boxing-new.png" class="menu-icon-sports-svg" />
              <span style="color: var(--white)"> Boxing </span>
            </a>
          </div>
          <div class="item-wrapper">
            <a @click="setSportMenu(31, 'Badminton')">
              <img src="/menu/badminton-new.png" class="menu-icon-sports-svg" />
              <span style="color: var(--white)"> Badminton </span>
            </a>
          </div>
        </div>
        <div class="col-6">
          <div class="item-wrapper">
            <a @click="setSportMenu(12, 'Rugby')">
              <img src="/menu/rugby-new.png" class="menu-icon-sports-svg" />
              <span style="color: var(--white)"> Rugby</span>
            </a>
          </div>
          <div class="item-wrapper">
            <a @click="setSportMenu(23, 'Volleyball')">
              <img
                src="/menu/volley-ball-new.png"
                class="menu-icon-sports-svg"
              />
              <span style="color: var(--white)"> Volleyball</span>
            </a>
          </div>
          <div class="item-wrapper">
            <a @click="setSportMenu(6, 'Handball')">
              <img src="/menu/handball-new.png" class="menu-icon-sports-svg" />
              <span style="color: var(--white)"> Handball</span>
            </a>
          </div>
          <div class="item-wrapper">
            <a @click="setSportMenu(117, 'MixedMartialArts')">
              <img src="/menu/Mma.png" class="menu-icon-sports-svg" />
              <span style="color: var(--white)"> Mixed Martial Arts</span>
            </a>
          </div>
          <div class="item-wrapper">
            <a @click="setSportMenu(22, 'Darts')">
              <img src="/menu/Darts.png" class="menu-icon-sports-svg" />
              <span style="color: var(--white)"> Darts</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sports",
  methods: {
    getKey: function (index) {
      return Math.random()
        .toString(11)
        .replace("0.", "sport-" + index + "-");
    },
    setSport: function (sportID) {
      document.getElementById("sports-btn").click();
      this.$store.dispatch("setSportID", sportID);

      if (this.$store.state.current_page === "home") return;

      this.$router.push({ name: "home" });
    },

    setSportMenu: function (sport_id, sport_name) {
      // this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));

      // this.sport = sport;
      document.getElementById("sports-btn").click();
      this.sport_name = sport_name;
      // this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id);

      //if(this.$route.path !== "/"){

      this.$router.push({ name: "sport", params: {} });
      //}
    },
    getMenuTextClass: function (sportID) {
      return parseInt(this.$store.state.sport_id) === parseInt(sportID)
        ? "ml-2 item-wrapper-span-active"
        : "ml-2 item-wrapper-span";
    },
    isActiveMenu: function (name) {
      return this.current_page === name ? "menu-active" : "";
    },
    resetGames: function () {
      this.$store.dispatch("resetAllGames");

      if (this.current_page !== "home") {
        this.goHome();
      }
    },
  },
  computed: {
    iconSize: function () {
      return 20;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    show_freebet: function () {
      var p = this.getProfile();
      if (!p) {
        return true;
      }

      return parseInt(p.b4) > 0;
    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {
        if (v.sport_id > 1) {
          t.push(v);
        }
      });

      return t;
    },
  },
};
</script>

<style scoped></style>
